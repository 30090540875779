<template>
  <div>
    <div class="top_video">
      <video
        v-if="isMobile"
        width="100%"
        :src="videoUrl"
        :autoplay="autoplay"
        loop muted playsinline
        preload="metadata" oncontextmenu="return false;"
        onmouseover="this.play()" onmouseout="this.pause();" onclick="this.muted=!this.muted;">
      </video>
      <div class="d-flex flex-column py-5 center" v-if="isMobile">
        <span class="mb-5 font-bold font-20">Explore the world with eventbox</span>
        <a :href="APP_STORE_URL" target="_blank" v-if="platform === 'iOS'">
          <img class="mx-5 store-image" :src="assets.app_store" :height="40" />
        </a>
        <a :href="GOOGLE_PLAY_URL" target="_blank" v-if="platform === 'Android'">
          <img class="store-image" :src="assets.google_play" :height="40" />
        </a>
      </div>
      <div class="p-15 vertical-center" v-else>
        <span class="ml-auto font-bold font-30">Explore the world with eventbox</span>
        <a :href="APP_STORE_URL" target="_blank">
          <img class="mx-5 store-image" :src="assets.app_store" :height="60" />
        </a>
        <a :href="GOOGLE_PLAY_URL" target="_blank">
          <img class="store-image" :src="assets.google_play" :height="60" />
        </a>
      </div>
    </div>

    <EventCarousel
      :perPageCustom="perPageCustom"
      :items="carouselItems"
    />

    <div :class="`d-flex vertical-center ${isMobile ? 'mx-8 mt-10' : 'mx-25 mt-20'}`">
      <span class="font-20 font-bold">Top Events</span>
      <div :class="`${isMobile ? 'w-50' : 'col-sm'} ml-5 separator separator-solid`" />
    </div>
    <div :class="`row ${isMobile ? 'mx-2' : 'mx-20'}`">
      <div class="col-lg-3 col-md-6 col-sm-12 my-2" v-for="(item, index) in topEventList" :key="(item, index)">
        <v-card class="mx-auto box-white p-0 pointer d-flex flex-column" @click="gotoEventDetail(item)">
          <v-img class="rounded event-image" :src="hostname === 'localhost' && FOR_MA ? index % 2 === 0 ? assets.testImage1 : assets.testImage2 : item.photoUrl" />
          <div class="m-3" :style="`position: relative; ${isMobile ? '' : 'height: 180px;'}`">
            <span class="mt-5 ellipsis font-bold font-18">{{ item.name }}</span>
            <div class="mt-2 vertical-center">
              <i class="fa fa-calendar-alt font-12 color-blue"></i>
              <span class="ml-2 font-12 color-blue">{{ getDateStringFromTimestamp(item.startAt) }} - {{ getDateStringFromTimestamp(item.endAt) }}</span>
            </div>
            <div class="mt-2 vertical-center">
              <i class="flaticon2-pin-1 font-12 color-purple"></i>
              <span class="ml-2 font-12 color-purple">{{ item.hideAddress ? $t('private') : getCountryCityFromAddress(item.address) }}</span>
            </div>
            <span class="my-2 ellipsis-2 font-12">{{ item.description }}</span>
            <div :class="`${isMobile ? 'd-flex justify-content-end' : ''}`" :style="`${isMobile ? '' : 'position: absolute; bottom: 0; right: 0;'}`">
              <v-btn class="button-small" @click="gotoEventDetail(item)" :disabled="!item.ticketCount">
                <span class="px-5">{{ $t('ticket') }}</span>
              </v-btn>
            </div>
          </div>
        </v-card>
      </div>
    </div>

    <div class="text-center my-20">
      <div @click="$router.push('/live_events')" class="see-all pointer text-hover-primary">
        <span>{{ $t('see_all_events') }}</span>
        <span style="vertical-align: text-top;">
          &nbsp;<i class="fa fa-angle-double-right" aria-hidden="true" style="font-size: 26px;"></i>
        </span>
      </div>
    </div>

    <p class="m-10">&nbsp;</p>

  </div>
</template>

<style scoped>
  .top_video {
    background-image: url('/media/image/header.jpg');
    background-size: cover;
  }
  .top_video span {
    color: white;
    line-height: 1;
    text-shadow: 0px 13px 21px rgba(0, 0, 0, 0.68);
  }
  .see-all {
    font-size: 21px!important;
    color: rgb(171, 180, 179)!important;
    font-weight: bold!important;
  }
</style>

<script>
import EventCarousel from '@/view/pages/events/EventCarousel.vue';

import { getPlatform, getDateStringFromTimestamp, getCountryCityFromAddress, getEventParam } from '../../functions';

import testImage1 from '@/assets/images/test_image1.jpg';
import testImage2 from '@/assets/images/test_image2.jpg';
import google_play from '@/assets/images/google_play.png';
import app_store from '@/assets/images/app_store.png';

export default {
  name: 'Home',
  components: {
    EventCarousel,
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    topEventList() {
      const liveEventList = this.$store.state.liveEventList;
      if (liveEventList) {
        const organizerList = liveEventList.filter(element => element.userId === 'LPJhYAJkl6SBeboygzZN').slice(0, 1); // Özbulut
        const topList = liveEventList.slice(0, Math.min(liveEventList.length, 4 - organizerList.length));
        return topList.concat(organizerList);
      } else {
        return [];
      }
    },
    carouselItems() {
      const liveEventList = this.$store.state.liveEventList;
      if (liveEventList) {
        const count = liveEventList.length;
        const carouselItems = [];
        for (var i=0; i<count; i++) {
          const index = Math.floor(i + count / 2) % count;
          carouselItems.push({
            eventParam1: getEventParam(liveEventList[i]),
            photoUrl1: liveEventList[i].photoUrl,
            eventParam2: getEventParam(liveEventList[index]),
            photoUrl2: liveEventList[index].photoUrl
          });
        }
        carouselItems.sort(() => Math.random() - 0.5);
        return carouselItems;
      } else {
        return [];
      }
    }
  },
  data() {
    return {
      assets: {
        testImage1,
        testImage2,
        google_play,
        app_store
      },
      hostname: window.location.hostname,
      videoUrl: 'https://firebasestorage.googleapis.com/v0/b/eventbox-c7c8f.appspot.com/o/ForWeb%2FIMG_9528.MOV?alt=media&token=b42ccf0e-db42-4e3d-9fa2-15ad728f45a8',
      autoplay: getPlatform() !== 'Desktop',
      platform: getPlatform(),
      perPageCustom: [
        [400, 2],
        [800, 3],
        [1200, 4],
        [1600, 5]
      ]
    };
  },
  mounted() {
    const redirectPath = localStorage.getItem('eventboxRedirectPath');
    if (redirectPath) {
      localStorage.setItem('eventboxRedirectPath', '');
      if (redirectPath === '/payment/checkout') {
        if (this.$store.state.myId) {
          this.$router.push(redirectPath);
        }
      } else {
        this.$router.push(redirectPath);
      }
    }
  },
  methods: {
    getDateStringFromTimestamp,
    getCountryCityFromAddress,
    gotoEventDetail(eventInfo) {
      this.$router.push(`/e/${getEventParam(eventInfo)}`);
    }
  }
};
</script>
